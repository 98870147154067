//@ts-nocheck
import React from 'react';
import { WebsiteWrapper } from '@parsleyhealth/cilantro-ui';
import {
  InstantSearch,
  connectCurrentRefinements
} from 'react-instantsearch-dom';
import BlogLayout from '../../../components/Blog/blog-layout';
import useCreateSearchClient from '../../../../src/lib/use-create-search-client';
import BlogSearchBar from '../../../components/Blog/nav/blog-search-bar';
import SearchResult from '../../../components/Blog/search-page-result';
import Pagination from '../../../components/Blog/pagination';
import CloseIcon from '../../../assets/icon-close.svg';
import './search.css';
const algoliaIndex = 'Blog';
const isBrowser = typeof window !== 'undefined';

const ClearRefinements = ({
  items,
  refine
}: {
  items: object[];
  refine: any;
  clearsQuery?: boolean;
}) => (
  <button
    className={`button-close-search ${
      items.length ? 'fade-in-close-x' : 'fade-out-close-x'
    }`}
    onClick={() => refine(items)}
    type="button"
  >
    <CloseIcon />
  </button>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

const Search = () => {
  const queryString = isBrowser ? window.location.search : '';
  const urlParams = new URLSearchParams(queryString);
  const initialQuery = urlParams.get('q');
  const searchClient = useCreateSearchClient();

  return (
    <BlogLayout>
      <WebsiteWrapper>
        <InstantSearch searchClient={searchClient} indexName={algoliaIndex}>
          <BlogSearchBar defaultRefinement={initialQuery}>
            <CustomClearRefinements clearsQuery />
          </BlogSearchBar>
          <SearchResult index={algoliaIndex} />
          <Pagination />
        </InstantSearch>
      </WebsiteWrapper>
    </BlogLayout>
  );
};

export default Search;
