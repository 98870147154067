//@ts-nocheck
import React, { useMemo } from 'react';

import { LayoutProps } from './index';
import { Navigation } from '../../redesign/organisms/navigation';
import MaskProvider from '../../redesign/context/mask-provider';
import BlogHeader from '../Blog/BlogHeader';
import InsightsHeader from '../insights/insights-main-nav';
import { Toggler } from '../../hooks/use-mobile-menu';
import './nav-switcher.css';
import HiltonHeader from '../HiltonEducation/HiltonHeader';

export type NavbarType = 'MAIN_SITE' | 'BLOG' | 'INSIGHTS' | 'HILTON';

const NavSwitcher: React.FC<LayoutProps> = ({
  navbarType = 'MAIN_SITE',
  spacer
}) => {
  const navbar = useMemo(() => {
    const navbarOptions: Record<NavbarType, JSX.Element> = {
      BLOG: <BlogHeader />,
      HILTON: <HiltonHeader />,
      MAIN_SITE: (
        <MaskProvider>
          <Navigation />
        </MaskProvider>
      ),
      INSIGHTS: <InsightsHeader />
    };
    return navbarOptions[navbarType];
  }, [navbarType]);

  return (
    <div
      className="nav-switcher"
      style={{ marginTop: spacer ? '70px' : '0px' }}
    >
      <Toggler.Provider>{navbar}</Toggler.Provider>
    </div>
  );
};

export default NavSwitcher;
