import React from 'react';
import { ThemeProvider, theme } from '../../styles';
import GlobalStyles from '../../styles/GlobalStyles';
import { Footer } from '../../redesign/organisms/footer/footer';
import { CurrentPathProvider } from '../../context/current-path-context';
import { OverlayProvider } from '../../context/overlay-context';
import { NavigationThemeProvider } from '../../context/navigation-theme-context';
import { ProvidersFilterProvider } from '../../context/providers-filter-context';
import { Location } from '@reach/router';
import NavSwitcher, { NavbarType } from './nav-switcher';
import { Layout as RedesignLayout } from '../../redesign/layouts/main-layout';

import {
  NavigationThemeKey,
  FakeHelpScoutButton
} from '@parsleyhealth/cilantro-ui';
import { useHelpscoutButton } from '../../hooks';
import { LayoutTheme } from '../../redesign/layouts/main-layout';

const isThirdPartyDisabled =
  process.env.GATSBY_DISABLE_THIRD_PARTY_INTEGRATIONS === 'true';

const setThemeRedesign = (location: string, children: React.ReactNode) => {
  let themeType: LayoutTheme;
  switch (location) {
    case '/insights/':
    case '/providers/':
      themeType = 'ivory';
      break;
    default:
      themeType = 'sherbed';
  }
  return <RedesignLayout theme={themeType}>{children}</RedesignLayout>;
};

export interface LayoutProps {
  transparent?: boolean;
  navFilter?: boolean;
  destination?: string;
  spacer?: boolean;
  readonly themeNav?: NavigationThemeKey;
  navbarType?: NavbarType;
  showNavbar?: boolean;
  showFooter?: boolean;
  enableScoutButton?: boolean;
  bypassRedesign?: boolean;
  children?: any;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  themeNav,
  spacer = false,
  transparent,
  destination,
  navFilter,
  navbarType = 'MAIN_SITE',
  showNavbar = true,
  showFooter = true,
  enableScoutButton = true,
  bypassRedesign = false
}) => {
  const { onInitHelpScout, color } = useHelpscoutButton();
  const isInsights = navbarType === 'INSIGHTS';
  const useRedesignLayout = bypassRedesign === false && !isInsights;

  return (
    <Location>
      {({ location }) => (
        <ThemeProvider theme={theme}>
          <NavigationThemeProvider>
            <CurrentPathProvider value={location.pathname}>
              <OverlayProvider>
                <ProvidersFilterProvider>
                  <GlobalStyles />
                  {useRedesignLayout ? (
                    setThemeRedesign(location.pathname, children)
                  ) : (
                    <div
                      style={{
                        position: 'relative',
                        backgroundColor: isInsights ? '#e7ded6' : 'transparent'
                      }}
                    >
                      {showNavbar ? (
                        <NavSwitcher
                          navbarType={navbarType}
                          transparent={transparent}
                          navFilter={navFilter}
                          destination={destination}
                          themeNav={themeNav}
                          spacer={spacer}
                        />
                      ) : null}
                      {!isThirdPartyDisabled && enableScoutButton && (
                        <FakeHelpScoutButton
                          onInitHelpScout={onInitHelpScout}
                          color={color}
                        />
                      )}
                      {children}
                      {showFooter && (
                        <Footer positionStatic={navbarType === 'BLOG'} />
                      )}
                    </div>
                  )}
                </ProvidersFilterProvider>
              </OverlayProvider>
            </CurrentPathProvider>
          </NavigationThemeProvider>
        </ThemeProvider>
      )}
    </Location>
  );
};
