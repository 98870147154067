import React from 'react';
import { navigate } from 'gatsby';
import { Highlight, Index, connectHits } from 'react-instantsearch-dom';
import './search-page-result.css';

type TResult = {
  title: string;
  objectId: string;
  slug: { current: string };
};

const Hits = ({ hits }: { hits: TResult[] }) => (
  <div className="container-search-result-page">
    {hits.map((hit: TResult) => (
      <button
        className="button-container-result"
        onClick={() => navigate(`/blog/${hit.slug.current}`)}
      >
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </button>
    ))}
  </div>
);

const CustomHits = connectHits(Hits);

const HitsInIndex = ({ index }: { index: string }) => (
  <Index indexName={index}>
    <CustomHits />
  </Index>
);

const SearchResult = ({ index }: { index: string }) => (
  <HitsInIndex index={index} />
);

export default SearchResult;
